import "core-js/features/array/find";
import "core-js/features/object/assign";
import "core-js/features/promise";
import "core-js/features/string/ends-with";
import "core-js/features/string/includes";
import "core-js/features/symbol/for";
import "core-js/features/weak-set";
import React from "react";
import ReactDOM from "react-dom";
import { AuthProvider } from "react-oidc-context";
import App from "./components/App";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as serviceWorker from "./serviceWorker";
import { Log } from "oidc-client-ts";

Log.setLogger(console);
Log.setLevel(Log.INFO);

const isPilot =
  window.location.hostname.includes("pilot") ||
  window.location.hostname.includes("staging");
const isLocal = window.location.hostname.includes("local");
const isProd = !isLocal && !isPilot;

const clientId = isProd
  ? "4Z9Io8Seikztin2pZqqCcCbKfHfljFbQ"
  : "2B3RN2v7tIMBYFg3qtXPvePAvEcMBtoS";
const authority = isProd
  ? "https://accounts.principal.com/oauth2/aus8xm6hson7W0A385d7"
  : "https://accounts.staging.principal.com/oauth2/aus4nvp00mFyV36By697";

let redirectUri;
if (isLocal) {
  redirectUri =
    "https://localhost.staging.principal.com:443/nq-participant/accounts-landing";
} else if (isPilot) {
  redirectUri =
    "https://nq.individuals.pilot.principal.com/nq-participant/accounts-landing";
} else {
  redirectUri =
    "https://nq.individuals.principal.com/nq-participant/accounts-landing";
}

//read add lines 25 - 36 from the old compare PR:
const startingUrl = localStorage.getItem("startingUrl");
const currentRoute = window.location.pathname + window.location.search;
if (!startingUrl || startingUrl === "null") {
  const currentTime = Date.now().toString();
  if (!isPopUp(currentRoute)) {
    localStorage.setItem("lastCacheTime", currentTime);
    console.log("storing: " + currentRoute + " at " + currentTime);
    localStorage.setItem("startingUrl", currentRoute);
  }
} else {
  console.log("storage had: " + localStorage.getItem("startingUrl"));
}

function onSigninCallbackFunc() {
  // This function is called when we get back from an Okta sign-in redirect.

  // Grab the previously stored Starting URL from local storage.
  const startingUrl = localStorage.getItem("startingUrl");
  console.log("in callback, starting URL is: " + startingUrl);

  if (isValidRoute(startingUrl)) {
    // If we have a valid Starting URL to send the user to, use it!
    window.location.href = startingUrl;
  } else {
    // This is in case there is an error state of an invalid route triggering, we kick the user to a previous page.
    // This is unlikely to happen, it is an edge case we included.
    recoverFromBadLocalStorage();
  }
}

function recoverFromBadLocalStorage() {
  console.log("recovering from bad cache data");
  localStorage.clear();
  const startingUrl = localStorage.getItem("startingUrl");
  window.location.href = "accounts-landing";
}

function isValidRoute(path) {
  const searchParams = new URLSearchParams(path);
  console.log(`checking if route is valid: ${path} .`);
  console.log(`search params: ${searchParams}.`);
  return (
    path &&
    path !== "null" &&
    !isPopUp(path) &&
    (path === "/" || path.includes("nq-participant")) &&
    !searchParams.has("code") &&
    !searchParams.has("state")
  );
}

function isPopUp(path) {
  const popupRoutes = ["investment-advisor"];
  let containsPopup = false;

  console.log("checking for popup routes in" + path);
  popupRoutes.forEach((route) => {
    if (path && path.includes(route)) {
      containsPopup = true;
    }
  });

  return containsPopup;
}

const oidcConfig = {
  scope: "openid profile api://ebs/individual-experience/header.read",
  authority: authority,
  client_id: clientId,
  redirect_uri: redirectUri,
  onSigninCallback: onSigninCallbackFunc,
};

ReactDOM.render(
  <AuthProvider {...oidcConfig}>
    <App />
  </AuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
